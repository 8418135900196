
import { mapStores } from "pinia"
import { useAccountStore } from "@/stores/account"
import PtzPresetItem from "@/components/ptz/PtzPresetItem"
import { PtzApi } from "@evercam/shared/api/ptzApi"
import PtzPresetForm from "@/components/ptz/PtzPresetForm"
import { AnalyticsEvent } from "@evercam/shared/types/analytics"

export default {
  components: {
    PtzPresetForm,
    PtzPresetItem,
  },
  props: {
    isMobile: {
      type: Boolean,
      default: false,
    },
    camera: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      activePreset: null,
      isCreatingPreset: false,
      presetList: [],
    }
  },
  computed: {
    ...mapStores(useAccountStore),
  },
  mounted() {
    this.fetchPresets()
  },
  methods: {
    selectPreset(preset, isEditing) {
      const { id, name } = preset
      this.activePreset = id

      const eventName = isEditing
        ? AnalyticsEvent.PTZToggleEditPresetForm
        : AnalyticsEvent.PTZPresetSelect
      this.$analytics.saveEvent(eventName, {
        presetId: id,
        presetName: name,
      })
    },
    async fetchPresets() {
      try {
        const response = await PtzApi.presets.index({
          cameraExid: this.camera.id,
        })

        this.presetList = [
          {
            name: "Home",
            id: "home",
          },
          ...response.presets.map((preset) => ({
            name: preset.name,
            id: preset.token,
          })),
        ]
        this.presetList = this.presetList.slice(0, 30)
      } catch (error) {
        console.log(error)
      }
    },
    closePresetCreation(update = false) {
      this.$analytics.saveEvent(AnalyticsEvent.PTZToggleCreatePresetForm)
      if (update) {
        this.fetchPresets()
      }
      this.isCreatingPreset = false
    },
    openPresetCreation() {
      this.$analytics.saveEvent(AnalyticsEvent.PTZToggleCreatePresetForm)
      this.isCreatingPreset = true
    },
  },
}
